import React from 'react'

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-widgets"></div>
            <div className="footer-text">
                <div className="footer-about">
                    <p>Welcome to GoodTribe!</p>
                    <p>GoodTribe is a technology platform that powers the retail and wholesale market with innovative A.I. based software solutions. It was founded in 2021 with a vision to put scalable and efficient business within everyone’s reach. In just over 6 months, it has evolved into an end-to-end supply chain enabler that gives brands, wholesalers, distributors, retailers, and factories access to trade, financing, and logistics on one platform.</p>
                </div>
                <div className="copyright">© Copyright GoodTribe 2022</div>
            </div>
        </div>
    )
}

export default Footer
