import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Layout from "./components/layout";
import Home from "./components/home";
import About from "./components/about";
import Contact from "./components/contact";
import Courses from "./components/courses";
import "./App.css";

function App() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/courses" element={<Courses />} />
                </Routes>
            </Layout>
            <Toaster />
        </Router>
    );
}

export default App;
