import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { getCourseName, submitCourseForm } from "../../utils/functions";

const CourseRegistrationForm = () => {
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [courseType, setCourseType] = useState("");
    const [course, setCourse] = useState("");
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => setCourse(""), [courseType]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (name === "" || mobile === "" || course === "") {
            toast.error("All fields are mandatory");
            return;
        }

        const payload = {
            name,
            mobile,
            course: getCourseName(course),
        };

        console.log("PAYLOAD", payload);
        //SUBMIT to lambda
        const success = await submitCourseForm(payload);
        if (success) {
            toast.success("Submitted!");
            setSubmitted(true);
        } else {
            toast.error("Something went wrong, please try again");
        }
    };

    return (
        <>
            {submitted ? (
                <h2>Thanks You for registering. We will contact you soon!!!</h2>
            ) : (
                <div className="registration-container">
                    <h2>Register for a course</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="mobile number"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                        />
                        <select
                            id="ctype"
                            value={courseType}
                            onChange={(e) => setCourseType(e.target.value)}
                        >
                            <option value="">Select Course Type</option>
                            <option value="student">Student Course</option>
                            <option value="professional">
                                Professional Course
                            </option>
                        </select>
                        {courseType !== "" && (
                            <>
                                {courseType === "student" ? (
                                    <select
                                        id="cname"
                                        value={course}
                                        onChange={(e) =>
                                            setCourse(e.target.value)
                                        }
                                    >
                                        <option value="">Select Course</option>
                                        <option value="c">
                                            Introduction to C
                                        </option>
                                        <option value="c++">
                                            Introduction to C++
                                        </option>
                                        <option value="python">
                                            Introduction to Python
                                        </option>
                                    </select>
                                ) : (
                                    <select
                                        id="cname"
                                        value={course}
                                        onChange={(e) =>
                                            setCourse(e.target.value)
                                        }
                                    >
                                        <option value="">Select Course</option>
                                        <option value="javascript">
                                            Introduction to web design with
                                            javascript
                                        </option>
                                        <option value="react">
                                            Advanced Frontend development with
                                            Reactjs
                                        </option>
                                        <option value="python+">
                                            Advanced Python for backend
                                            development
                                        </option>
                                        <option value="fullstack">
                                            Advanced Fullstack development with
                                            node and react
                                        </option>
                                        <option value="react-native">
                                            Mobile app development with React
                                            Native
                                        </option>
                                    </select>
                                )}
                            </>
                        )}
                        <input type="submit" value="Register" />
                    </form>
                </div>
            )}
        </>
    );
};

export default CourseRegistrationForm;
