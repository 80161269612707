import styled from "styled-components";

import Advantages from "./Advantages";

const Technology = () => {
    return (
        <Container className="fluid-container">
            <div className="body-container">
                <h2>We are committed to mitigating technological limitations in key stages of commerce</h2>
                <BackgroundImage />
                <Advantages />
            </div>
        </Container>
    );
};

const Container = styled.div`
  background: rgba(215,225,232,0.3);
  padding: 50px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  border-radius:20px;

  & h2 {
    color: #22222a;
    font-family:"Ubuntu Medium";
    font-size:2.8rem;
    text-align:center;
    margin-bottom:5rem;

    @media (max-width: 480px) {
        font-size:1.2rem;
    }
  
    @media (min-width: 481px) and (max-width: 768px) {
        font-size:2rem;
    }

    @media (max-width: 1200px) {
        padding: 50px 5% 5% 5%;
    }
  }
`;

const BackgroundImage = styled.div`
    margin:auto;
    width: 90%;
    height:100px;
    padding-bottom: 60%;
    background-image:url('https://sss-retail-images.s3.amazonaws.com/goodtribecloud-desktop.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;

    @media (max-width: 480px) {
        background-image:url('https://sss-retail-images.s3.amazonaws.com/goodtribecloud-mobile.png');
        padding-bottom: 80%;
        width:100%;
    }
`

export default Technology;
