import HeroSectionCarousal from "./HeroSectionCarousal";
import Technology from './Technology'
import GCampaigne from "./GCampaigne"
import GWholesaleSection from "./GWholesaleSection";
import React from "react";

const Home = () => {
  return (
    <div>
      <HeroSectionCarousal />
      <Technology />
      <GCampaigne />
      <GWholesaleSection />
    </div>
  );
};

export default Home;
