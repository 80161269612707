import styled from "styled-components";
import React from "react";

const Slide = ({ item }) => {
  const { title, desc, image, cta } = item

  return (
    <div className="heroCarousal-container">
      <Content>
        <h2>
          {title}
        </h2>
        <p>{desc}</p>
        <a href={cta.url}>{cta.text}</a>
      </Content>
      <SlideImage src={image} alt="goodtribe" />
    </div>
  );
};

const Content = styled.div`
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 480px) {
    padding-top:50px;
    gap: 20px;
    padding-right:0;
  }

  & > h2 {
    font-family: "Ubuntu Medium";
    font-size: 4rem;
    padding: 10px 0;

    @media (max-width: 480px) {
      font-size: 2rem;
      padding:0;
    }

    @media (min-width: 481px) and (max-width: 768px) {
      font-size: 2.5rem;
      padding:0;
    }

    @media (min-width: 769px) and (max-width: 992px) {
      font-size: 3rem;
      padding:0;
    }
  }
  & > p {
    font-size: 1.2rem;
    line-height: 1.5;

    @media (max-width: 480px) {
      font-size: 1rem;
      line-height: 1;
      text-align:justify;
    }
  }
  & > a {
    width: fit-content;
    max-width: 100%;
    text-decoration:none;
    background: black;
    color:#fefefe;
    padding: 5px 10px;
  }
`

const SlideImage = styled.img`
  display:block;
  width: 50%;
  @media (max-width: 480px) {
    width: 80%;
  }
`;


export default Slide;
