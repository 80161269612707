import React, { useState, useEffect, useRef } from "react";
import Slide from "../blocks/Slide";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

const slides = [
    {
        desc: "Making retail operations hassle-free with integrated ERP, point of sale system and optimized to utmost efficiency with real time data analysis and AI enabled sales forecasting system to avoid overstocking and end to end solutions for product sourcing, inventory management, and easy re-ordering",
        title: "G Retail",
        image: "https://sss-retail-images.s3.amazonaws.com/retail.png",
        cta: {
            text: "Learn More",
            url: "#",
        },
    },
    {
        desc: "Providing a seamless trade between manufacturers and wholesalers with no multiple intermediaries in between, vast come-at-able line of logistics, and a vast catalogue of products to choose from at the most competitive prices.",
        title: "G B2B",
        image: "https://sss-retail-images.s3.amazonaws.com/wholesale.png",
        cta: {
            text: "Learn More",
            url: "http://trade.goodtribe.io/",
        },
    },
    {
        desc: "Empowering small and medium manufacturers by amplifying the market with extensive product catalogue publications on all platforms, factory digitization, and AI enabled trend forecasting system for an efficient production.",
        title: "G Empower",
        image: "https://sss-retail-images.s3.amazonaws.com/empower.png",
        cta: {
            text: "Learn More",
            url: "#",
        },
    },
];

const slideVariants = {
    hidden: (direction) => {
        return {
            x: direction > 0 ? "100vw" : "-100vw",
        };
    },
    visible: {
        x: 0,
    },
    exit: (direction) => {
        return {
            x: direction < 0 ? "100vw" : "-100vw",
            transition: { ease: "easeInOut" },
        };
    },
};

const buttonVariants = {
    hidden: {
        scale: 1,
        opacity: 0.1,
    },
    visible: {
        opacity: 0.5,
        scale: 1.1,
    },
};

const HeroSectionCarousal = () => {
    const [visibleIndex, setVisibleIndex] = useState(0);
    const [dir, setDir] = useState(1);
    const [currentX, setCurrentX] = useState(0);

    const sliderCard = useRef();

    function onDragStart(_, info) {
        setCurrentX(info.point.x);
    }

    function onDragEnd(_, info) {
        const difference = info.point.x - currentX;
        const slideWidth = sliderCard.current.scrollWidth;
        if (slideWidth / difference < 6) {
            const direction = difference < 0 ? 1 : -1;
            handleClick(direction);
        }
        setCurrentX(0);
    }

    useEffect(() => {
        const slideInterval = setInterval(() => handleClick(1), 7000);

        return () => clearInterval(slideInterval);
    });

    const handleClick = (inc) => {
        setDir(inc);
        if (visibleIndex + inc < 0) {
            setVisibleIndex(slides.length - 1);
        } else if (visibleIndex + inc === slides.length) {
            setVisibleIndex(0);
        } else {
            setVisibleIndex(visibleIndex + inc);
        }
    };
    return (
        <div className="body-container">
            <div className="heroCarousal">
                <AnimatePresence exitBeforeEnter custom={dir}>
                    <motion.div
                        ref={sliderCard}
                        key={visibleIndex}
                        custom={dir}
                        variants={slideVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        drag="x"
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={2}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                    >
                        <Slide item={slides[visibleIndex]}></Slide>
                    </motion.div>
                </AnimatePresence>

                <motion.button
                    className="btn-nav left"
                    onClick={() => handleClick(-1)}
                    variants={buttonVariants}
                    animate="hidden"
                    whileHover="visible"
                >
                    {" "}
                    &#60;{" "}
                </motion.button>
                <motion.button
                    className="btn-nav right"
                    onClick={() => handleClick(1)}
                    variants={buttonVariants}
                    animate="hidden"
                    whileHover="visible"
                >
                    {" "}
                    &#62;{" "}
                </motion.button>
            </div>
        </div>
    );
};

export default HeroSectionCarousal;
