import Header from "./header";
import Footer from "./footer";
import React from 'react'

const Layout = ({ children }) => {
    return (
        <div>
            <Header />
            <div>
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default Layout
