import React from "react";
import CourseInfo from "../blocks/CourseInfo";

const index = () => {
    return (
        <div>
            <CourseInfo />
        </div>
    );
};

export default index;
