import React from "react";
import { Link } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import DrawerComponent from "./DrawerComponent";

const Header = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <div className="header">
            <div className="logo">
                <img
                    src="https://sss-retail-images.s3.amazonaws.com/gt-logo.png"
                    alt="logo"
                />
            </div>
            {isMobile ? (
                <DrawerComponent />
            ) : (
                <ul className="links">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    {/* <li>
                        <Link to="/about">About</Link>
                    </li> */}
                    <li>
                        <a
                            href="http://trade.goodtribe.io/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Trade
                        </a>
                    </li>
                    {/* <li>
                        <Link to="/contact">Contact</Link>
                    </li> */}
                    <li>
                        <Link to="/courses">Courses</Link>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default Header;
