import React from "react";
import styled from "styled-components";

const GWholesaleSection = () => {
    return (
        <Container>
            <div className="body-container">
                <h2>G-B2B</h2>
                <img
                    src="https://sss-retail-images.s3.amazonaws.com/wholesale-animation.png"
                    alt=""
                />
                <p>
                    Provides a seamless trade between manufacturers and
                    wholesalers with retailers with no intermediaries in
                    between.
                    <br />
                    <b>1.</b> Browse from a vast catalogue of products to choose
                    from at the most accessible factory prices
                    <br />
                    <b>2.</b> Vast come-at-able line of logistics for product
                    shipments from factory to wholesale retailers directly
                    <br />
                    <b>3.</b> Cut down the levels of wholesale maneuver by
                    making purchases directly from the manufacturers with no
                    intermediaries{" "}
                </p>
            </div>
        </Container>
    );
};

const Container = styled.div`
    margin-top: 50px;
    background: rgba(215, 225, 232, 0.3);
    width: 100%;
    min-height: 300px;
    padding-bottom: 30px;

    & h2 {
        padding-top: 50px;
        color: #22222a;
        font-family: "Ubuntu Medium";
        font-size: 2.8rem;
        text-align: center;
        margin-bottom: 5rem;

        @media (max-width: 480px) {
            font-size: 1.2rem;
        }

        @media (min-width: 481px) and (max-width: 768px) {
            font-size: 2rem;
        }
    }

    & img {
        display: block;
        margin: auto;
        max-width: 90%;
    }

    & p {
        max-width: 600px;
        width: 90%;
        margin: auto;
        text-align: center;
        font-size: 1.2rem;
        line-height: 1.5;

        @media (max-width: 480px) {
            font-size: 1rem;
            text-align: left;
        }
    }
`;

export default GWholesaleSection;
