import React from 'react'
import styled from 'styled-components'

const GCampaigne = () => {
    return (
        <div className='body-container'>
            <Container>
                <div>
                    <h2>G-Campaign</h2>
                    <p>Integrated digital outreach strategy enables retail brands to reach out to their customers through all social media platforms. Our marketplace integration features ensure brands to reach a wider audience with seamless inventory management. Performance driven lead generation campaigns enable B2B players to get the real leads in the most efficient way.</p>
                </div>
                <div>
                    <img src="https://sss-retail-images.s3.amazonaws.com/stats.png" alt="g-campaign" />
                </div>
            </Container>
        </div>
    )
}

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    padding-bottom:20px;
    & > div{
        flex:1;
        min-width: 300px;
    }

    & h2{
        font-family:"Ubuntu Medium";
        font-size:2.8rem;

        @media (max-width: 480px) {
            font-size:1.2rem;
        }
    
        @media (min-width: 481px) and (max-width: 768px) {
            font-size:2rem;
        }
    }

    & p{
        font-size:1.2rem;
        line-height:1.2;
        margin-top:20px;

        @media (max-width: 480px) {            
            font-size: 1rem;
            line-height: 1;
            text-align:justify;
        }
    }

    & img{
        display:block;
        width:40%;
        margin: auto;
    }

    @media (max-width: 480px) {
        width:90%;
        margin:auto;
        gap:20px;
    }
`

export default GCampaigne