export const getCourseName = (shortName) => {
    switch (shortName) {
        case "c":
            return "Introduction to C programming";
        case "c++":
            return "Introduction to C++";
        case "python":
            return "Introduction to Python";
        case "javascript":
            return "Introduction to web design with javascript";
        case "react":
            return "Advanced Frontend development with Reactjs";
        case "python+":
            return "Advanced Python for backend development";
        case "fullstack":
            return "Advanced Fullstack development with node and react";
        case "react-native":
            return "Mobile app development with React Native";
        default:
            return "Not Chosen";
    }
};

export const submitCourseForm = async (payload) => {
    console.log("REached", payload);
    const URL =
        "https://cc2sxvpch2.execute-api.ap-south-1.amazonaws.com/alpha/SESsendemail";
    const response = await fetch(URL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    });
    const data = await response.json();
    return data.success;
};
