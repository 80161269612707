import React from "react";
import CourseRegistrationForm from "./CourseRegistrationForm";

const CourseInfo = () => {
    return (
        <>
            <div
                className="hero padding-small flex-col"
                style={{ marginTop: "40px" }}
            >
                <div>
                    <h3>Student Courses:</h3>
                    <ul style={{ marginTop: "20px" }}>
                        <li>Introduction to C programming</li>
                        <li>Introduction to C++</li>
                        <li>Introduction to Python</li>
                    </ul>
                    <p>*All courses are monthly with a fee of ₹4000/month</p>
                </div>
                <div>
                    <h3>Professional Courses:</h3>
                    <ul style={{ marginTop: "20px" }}>
                        <li>Introduction to web design with javascript</li>
                        <li>Advanced Frontend development with Reactjs</li>
                        <li>Advanced Python for backend development</li>
                        <li>
                            Advanced Fullstack development with node and react
                        </li>
                        <li>Mobile app development with React Native</li>
                    </ul>
                    <p>
                        *All are 3-months long courses with fees ranging from
                        ₹15000 to ₹30000
                    </p>
                </div>
            </div>
            <div className="hero padding-small flex-col">
                <CourseRegistrationForm />
            </div>
            <div className="hero padding-small">
                <div>
                    <h3>Address:</h3>
                    <p>House no.131 Risikesh, 3rd Floor</p>
                    <p>Vinayak Path, Lakhimi Nagar</p>
                    <p>Hatigaon, Guwahati-781038</p>
                    <p>Landmark: Shubham Enclave</p>
                    <p>Call: +91-8638903663</p>
                    <p>
                        <a
                            target="_blank"
                            href="https://www.youtube.com/@proproprogramming"
                            rel="noreferrer"
                        >
                            Yotube Channel Partner
                        </a>
                    </p>
                    <br />
                    <a
                        href="mailto:goodtribefashion@gmail.com"
                        style={{
                            background: "dodgerblue",
                            color: "white",
                            padding: "3px 10px",
                            textDecoration: "none",
                        }}
                    >
                        Register By Email
                    </a>
                </div>
            </div>
            <img
                style={{
                    display: "block",
                    maxWidth: "100%",
                    backgroundSize: "cover",
                }}
                alt="courses"
                src="https://sss-retail-images.s3.amazonaws.com/courses.jpg"
            />
        </>
    );
};

export default CourseInfo;
