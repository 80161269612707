import styled from "styled-components";

const Advantages = () => {
  return (
    <Container>
      <h2> The Good Tribe Advantage </h2>
      <CardBox>
        <Card>
          <h3>G Stats</h3>
          <img className="image" src="https://sss-retail-images.s3.amazonaws.com/stats.png" alt="" />
          <p>Graphic representation of trade statistics on every aspect of your business will empower you to focus on key areas and increase sales with time.</p>
        </Card>
        <Card>
          <h3>G Predict</h3>
          <img className="image" src="https://sss-retail-images.s3.amazonaws.com/predict.png" alt="" />
          <p>An A.I./M.L. driven prediction algorithm which predicts sales patterns for retail, design trends for manufacturers and raw material estimations for factories.</p>
        </Card>
        <Card>
          <h3>G Connect</h3>
          <img className="image" src="https://sss-retail-images.s3.amazonaws.com/connect.png" alt="" />
          <p>Our Machine Learning model will analyze the geographic location and trading pattern to match buyers and sellers and also minimize the logistic costs for retailer</p>
        </Card>
      </CardBox>
    </Container>
  );
};

const Container = styled.div`
  background: rgba(215,225,232,0.7);
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius:20px;

  & > h2 {
    color: #22222a;
    font-family:"Ubuntu Medium";
    font-size:2rem;
  }
`;

const CardBox = styled.div`
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

const Card = styled.div`
  width: 300px;
  padding: 20px;
  display: flex;
  gap:20px;
  flex-direction: column;
  align-items: center;

  & > h3{
    font-family:"Ubuntu Medium";
  }

  & > img{
    width:60%;
    display:block;
  }

  & > p{
    text-align:justify;
    font-weight:400;
    color: #555;
  }
`;

export default Advantages;
